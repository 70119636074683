/**
 * requestAnimationFrame Polyfill
 */
const requestAnimationFrame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

export { requestAnimationFrame };

/**
 * performance.now Polyfill
 */
const now =
  window.performance && window.performance.now
    ? () => window.performance.now()
    : () => new Date().getTime();

/**
 * Loop Helper
 * creates a continuus loop utilizing requestAnimationFrame
 *
 * @export
 * @param {function} update
 * @param {function} render
 * @param {number} [fps=60]
 * @param {boolean} [autostart=true]
 * @returns {object} loop-controls
 */
export function loop(update, render, fps = 30, autostart = true) {
  const startTime = now();
  let fpsInterval = 1000 / fps;
  let frameTime = startTime;
  let stopped = true;
  let paused = false;

  const step = () => {
    const currentTime = now();
    const deltaTime = (currentTime - frameTime) / 1000;

    if (stopped) {
      return;
    }

    requestAnimationFrame(step);

    if (!paused && (fps === 60 || deltaTime >= fpsInterval / 1000)) {
      update(deltaTime);
      render(deltaTime);
      frameTime = currentTime;
    }
  };

  const loopInstance = {
    stop() {
      stopped = true;
      return this;
    },
    pause() {
      paused = true;
      return this;
    },
    start() {
      paused = false;
      if (stopped) {
        stopped = false;
        requestAnimationFrame(step);
      }
      return this;
    },
    setFPS(fps) {
      fpsInterval = 1000 / fps;
    },
  };

  if (autostart) {
    loopInstance.start();
  }

  return loopInstance;
}

export const ucfirst = s => s.charAt(0).toUpperCase() + s.substr(1);
export const lcfirst = s => s.charAt(0).toLowerCase() + s.substr(1);

export const devGUI = configure => {
  //const dat = require('dat.gui');
  //const gui = new dat.GUI;
  // configure(gui);
};

export const omit = (obj, ...list) => {
  const copy = Object.assign({}, obj);
  list.forEach(key => delete copy[key]);
  return copy;
};
