import classNames from 'classnames';
import React, { Component } from 'react';
import * as content from '../content';
import { Link } from './router';
import Icon from './icon';

export default class ResponsiveNav extends Component {
  state = {
    open: false,
    selected: null,
  };

  select = selected => {
    return e => {
      e.stopPropagation();
      this.setState({
        selected: this.state.selected === selected ? null : selected,
      });
    };
  };

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { active, surfaceKey } = this.props;
    const { open } = this.state;
    let key = 0;
    return (
      <div
        className={classNames('nube-responsive-nav', { open: open && active })}
        onClick={this.toggle}
      >
        {active && (
          <button className="menubtn">
            <Icon which="menu" />
          </button>
        )}
        <nav onClick={() => this.setState({ open: false })}>
          <ul>
            <li className="submenu">
              <button onClick={this.select('colors')}>
                Farbtöne{' '}
                <Icon
                  which={this.state.selected === 'colors' ? 'minus' : 'plus'}
                />
              </button>
              <ul
                className={classNames({
                  open: this.state.selected === 'colors',
                })}
              >
                {Object.keys(content.nube.colors).map(cKey => {
                  return (
                    <li key={++key}>
                      <Link to={`/${cKey}/${surfaceKey}`}>
                        {content.nube.colors[cKey].title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
            {/*}
						<li>
							<button onClick={this.select("surfaces")}>Oberflächen</button>
							<ul className={classNames({open:this.state.selected==='surfaces'})}>
								{Object.keys(content.nube.surfaces).map((sKey)=>{
									return (
										<li key={++key}>
											<Link to={`/${colorKey}/${sKey}`}>{content.nube.surfaces[sKey].title}</Link>
										</li>
									);
								})}
							</ul>
						</li>
						*/}
            {content.header.items.map(item => {
              const { title, href, to } = item;
              return (
                <li key={++key}>
                  <Link {...{ title, href, to }}>{item.title}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}
