import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import canvas from '../canvas';

export default class SceneWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scene: null,
      nubeHasMouse: false,
    };
  }

  componentDidMount() {
    const canvasMount = ReactDOM.findDOMNode(this.refs.canvasMount);
    const scene = canvas(canvasMount);
    this.state.scene = scene; // dont trigger update?!
    this.setSceneState(this.props, scene);

    const dispatcher = scene.domElement;
    dispatcher.addEventListener('mouseover3d', this.handleNubeMouseover);
    dispatcher.addEventListener('mouseout3d', this.handleNubeMouseout);
    dispatcher.addEventListener('click3d', this.handleNubeClick);
  }

  componentWillUnmount() {
    if (this.state.scene) {
      this.state.scene.stop();
    }
  }

  componentDidUpdate() {
    this.setSceneState(this.props);
  }

  setSceneState(sceneState, scene) {
    scene = scene || this.state.scene;
    if (!scene) {
      throw new Error('tried to set scene state on null');
    }
    scene.setColorKey(sceneState.colorKey);
    scene.setSurfaceKey(sceneState.surfaceKey);
    scene.setHighFPSMode(sceneState.highFPS);
  }

  handleNubeMouseover = ({ detail }) => {
    this.setState({ nubeHasMouse: true });
    const nuanceIndex = this.resolveNuanceIndexFromFace(detail);
    if (this.props.onMouseOver) this.props.onMouseOver(nuanceIndex);
  };

  handleNubeMouseout = ({ detail }) => {
    this.setState({ nubeHasMouse: false });
    const nuanceIndex = -1;
    if (this.props.onMouseOut) this.props.onMouseOut(nuanceIndex);
  };

  handleNubeClick = ({ detail }) => {
    const nuanceIndex = this.resolveNuanceIndexFromFace(detail);
    if (this.props.onClick) this.props.onClick(nuanceIndex);
  };

  resolveNuanceIndexFromFace({ face, object }) {
    const activeLayer = object.material[face.materialIndex].map.findLayer({
      active: true,
    }); // höhö is ditt hässlich
    return activeLayer ? activeLayer.nuanceIndex : -1;
  }

  render() {
    const { nubeHasMouse } = this.state;
    return (
      <div
        ref="canvasMount"
        className="nube"
        style={{ cursor: nubeHasMouse ? 'pointer' : 'default' }}
      />
    );
  }
}
