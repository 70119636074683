import React from 'react';
import classNames from 'classnames';
import { omit } from '../util';

export const Container = props => (
  <div
    {...omit(props, 'fluid')}
    className={classNames(
      props.fluid ? 'container-fluid' : 'container',
      props.className,
    )}
  />
);

export const Row = props => (
  <div {...props} className={classNames('row', props.className)} />
);

export const Col = props => (
  <div
    {...omit(props, 'xs', 'sm', 'md', 'lg', 'xl', 'pullLeft', 'pullRight')}
    className={classNames(
      {
        [`col-xs-${props.xs}`]: !!props.xs,
        [`col-sm-${props.sm}`]: !!props.sm,
        [`col-md-${props.md}`]: !!props.md,
        [`col-lg-${props.lg}`]: !!props.lg,
        [`col-xl-${props.xl}`]: !!props.xl,
        'pull-right': props.pullRight,
        'pull-left': props.pullLeft,
      },
      props.className,
    )}
  />
);
