import React, { Component } from 'react';
import { Container, Row, Col } from './grid';
import classNames from 'classnames';
import Icon from './icon';

const swallow = e => e.stopPropagation();

export default class Sidebar extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    bdHasMouse: false,
    leaving: false,
  };

  handleClose = e => {
    let parent = e.target;

    while (parent) {
      if (parent.classList && parent.classList.contains('closebtn')) break;
      if (parent.classList && parent.classList.contains('nube-sidebar__inner'))
        return;
      parent = parent.parentNode;
    }

    this.leave();
  };

  leave() {
    this.setState({ leaving: true });
    this.props.onLeave && this.props.onLeave();
    setTimeout(() => {
      this.context.router.history.push('/');
      this.setState({ leaving: false });
      this.props.onClose && this.props.onClose();
    }, 500);
  }

  render() {
    const size = this.props.size || 12;

    const closeOnBackdrop =
      'closeOnBackdrop' in this.props ? this.props.closeOnBackdrop : true;

    return (
      <div
        className={classNames('nube-sidebar', {
          'tease-close': closeOnBackdrop && this.state.bdHasMouse,
          leave: this.state.leaving,
        })}
        onClick={closeOnBackdrop ? this.handleClose : undefined}
        onMouseOver={() => this.setState({ bdHasMouse: true })}
        onMouseOut={() => this.setState({ bdHasMouse: false })}
      >
        <Container fluid={true}>
          <Row>
            <Col
              lg={size}
              pullRight={true}
              onMouseOver={closeOnBackdrop ? swallow : undefined}
              onMouseOut={closeOnBackdrop ? swallow : undefined}
            >
              <div className="nube-sidebar__inner">
                <button
                  className="closebtn static"
                  onClick={this.handleClose}
                  onMouseOver={swallow}
                  onMouseOut={swallow}
                >
                  <Icon which="cross" />
                </button>
                {this.props.children}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

//<button className="nube-sidebar__close-btn"><Icon which="close" /></button>
