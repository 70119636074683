import { loop } from '../../util';

export default class LoopBehaviour {
  constructor() {
    this.speed = 1;
  }

  start() {
    const dispatchUpdate = this.dispatch('update');
    const dispatchRender = this.dispatch('render');
    this.loop = loop(dispatchUpdate, dispatchRender);
  }

  dispatch(which) {
    return delta => {
      if (
        !this.parent ||
        !(which in this.parent) ||
        typeof this.parent[which] !== 'function'
      )
        return;
      this.parent[which](delta * this.speed);
    };
  }

  stop() {
    if (!this.loop) {
      this.loop.stop();
    }
  }
}
