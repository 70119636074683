import React, { Component } from 'react';
import { createHashHistory } from 'history';
import RouteParser from 'route-parser';
import classNames from 'classnames';

//export const history = process.env.NODE_ENV==='production'?createBrowserHistory():createHashHistory();
export const history = createHashHistory();

export const Link = props => {
  props = Object.assign({}, props);
  const { to } = props;
  const Component = props.component || 'a';
  delete props.component;

  const push = e => {
    e.preventDefault();
    if (history.location.pathname === to) return;
    history.push(to);
  };

  if (to) {
    props.onClick = push;
    if (Component === 'a') {
      props.role = 'button';
      props.href = '/#' + props.to;
    }
    delete props.to;
  }

  return <Component {...props} />;
};

Link.contextTypes = { router: () => null };

export default class Router extends Component {
  static childContextTypes = {
    router: () => null,
  };

  componentDidMount() {
    const initialState = this.getStateUpdate(history.location);
    if (typeof initialState.route === 'function')
      initialState.route = initialState.route();
    this.setState(initialState);
    let blocked = false;
    history.listen(location => {
      if (blocked) return;
      blocked = true;
      const lastRouteKey = this.state.routeKey;
      const stateUpdate = this.getStateUpdate(location);
      this.transitionRoutes(lastRouteKey, stateUpdate).then(
        () => (blocked = false),
      );
    });
  }

  transitionRoutes(lastRouteKey, stateUpdate) {
    const lastRoute = this.refs[`route${lastRouteKey}`];
    const handleComplete = () => {
      if (typeof stateUpdate.route === 'function')
        stateUpdate.route = stateUpdate.route();
      lastRoute &&
        lastRouteKey !== stateUpdate.routeKey &&
        lastRoute.setState({ active: false, params: lastRoute.makeParams({}) });
      this.setState(stateUpdate);
    };

    if (lastRoute && stateUpdate.routeKey !== lastRouteKey) {
      return lastRoute.beforeLeave().then(handleComplete);
    } else {
      handleComplete();
      return Promise.resolve();
    }
  }

  getStateUpdate = location => {
    const [route, routeKey] = this.getMatch(location) || [null, null];
    const newState = { location, route, routeKey };
    if (route && typeof route === 'string') {
      if (location.pathname !== route) {
        history.push(route);
        return this.getStateUpdate(history.location);
      } else {
        throw new Error('tried reroute to same path (' + route + ')');
      }
    }
    return newState;
  };

  getMatch({ pathname }) {
    const routes = React.Children.toArray(this.props.children);

    let match = null;
    for (let key in routes) {
      const route = this.refs[`route${key}`];
      if (!route) {
        continue;
      }
      const params = route.match(pathname, !!match);
      if (params && match === null) {
        match = [params, key];
      }
    }

    return match;
  }

  getChildContext() {
    return { router: Object.assign({ history }, this.state) };
  }

  render() {
    const Comp = this.props.component;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child, key) => React.cloneElement(child, { ref: `route${key}` }),
    );

    return <Comp>{childrenWithProps}</Comp>;
  }
}

export class Route extends Component {
  state = {
    active: false,
    params: {},
  };

  match(pathname, skip = false) {
    const { path, reroute } = this.props;
    let params;

    if (!skip) {
      if (path === '*') {
        params = {};
      } else {
        const parser = new RouteParser(path);
        params = parser.match(pathname);
      }
    }

    const fullParams = this.makeParams(params);

    if (params && reroute) {
      return reroute;
    } else if (params) {
      return () => {
        this.setState({ active: !!params, params: fullParams });
        return fullParams;
      };
    } else {
      return null;
    }
  }

  beforeLeave() {
    if (this.props.beforeLeave) return this.props.beforeLeave();
    this.setState({ active: false });
    if (this.props.timeout) {
      return new Promise(res => {
        setTimeout(res, this.props.timeout);
      });
    }
    return Promise.resolve();
  }

  makeParams(newParams) {
    const defaults = this.props.defaultParams || {};
    return Object.assign({}, defaults, newParams);
  }

  render() {
    const Comp = this.props.component;
    if (!Comp) return null;
    const childProps = Object.assign({}, this.props, this.state, {
      params: this.makeParams(this.state.params),
    });
    delete childProps.component;
    delete childProps.path;

    if (!Comp) return null;

    return (
      <div
        className={classNames(`nube-view` || this.props.viewClassName, {
          active: this.state.active,
        })}
      >
        {/*active&&*/ <Comp {...childProps} />}
      </div>
    );
  }
}
