import classNames from 'classnames';
import React, { Component } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

const SlideIn = props => (
  <CSSTransition {...props} timeout={300} classNames="slide-in">
    {props.children}
  </CSSTransition>
);

export default class NuanceLabel extends Component {
  render() {
    const { open, nuances, nuanceIndex } = this.props;
    return (
      <div className={classNames('nube-nuance-label', { open })}>
        {nuances &&
          nuances.map((n, i) => (
            <SlideIn key={i} in={i === nuanceIndex}>
              <span>{n.title}</span>
            </SlideIn>
          ))}
      </div>
    );
  }
}
