import { Texture, GammaEncoding } from 'three/build/three.module';

import { traverseable } from './prefab';

export default class MultiTexture extends traverseable('layer', Texture) {
  constructor(layers = [], stretchfit = true, debug = false) {
    const canvas = document.createElement('canvas');
    super(canvas);
    this.debug = debug;
    this.canvas = canvas;
    this.context = this.canvas.getContext('2d');
    this.layers = [];
    this.stretchfit = stretchfit;
    this.encoding = GammaEncoding;
    layers.forEach(this.add);
    if (this.debug) {
      layers[0].onUpdate = console.log;
    }
  }
  add = tex => {
    this.layers.push(tex);

    tex.onUpdate = this.compose;

    if (tex.image) {
      this.compose();
    }
  };
  remove = tex => {
    this.layers.splice(this.layers.indexOf(tex), 1);
    this.compose();
  };
  compose = () => {
    let width = 16,
      height = 16;
    this.eachLayer(child => {
      let { image } = child;
      if (child.canvas) {
        image = child.canvas;
      }
      if (!image) return;
      if (image.width > width) width = image.width;
      if (image.height > height) height = image.height;
    });
    Object.assign(this.canvas, { width, height });
    const { context } = this;
    context.clearRect(0, 0, width, height);
    this.eachLayer(child => {
      let { image } = child;
      if (!image && child.canvas) {
        image = child.canvas;
      }
      if (!image) return;
      const opacity = +child.opacity;
      if ('opacity' in child && opacity === 0) return;
      context.save();
      context.globalAlpha = opacity || 1;
      if (!this.stretchfit) {
        context.drawImage(
          image,
          (width - image.width) / 2,
          (height - image.height) / 2,
        );
      } else {
        context.drawImage(
          image,
          0,
          0,
          image.width,
          image.height,
          0,
          0,
          width,
          height,
        );
      }

      context.restore();
    });

    this.needsUpdate = true;
  };
}
