import {
  Scene,
  Vector3,
  CurvePath,
  QuadraticBezierCurve3,
  PointLight,
} from 'three/build/three.module';

import Prefab from '../prefab';
import RendererBehaviour from '../behaviours/renderer';
import LoopBehaviour from '../behaviours/loop';
import CameraBehaviour from '../behaviours/camera';
import StudioEnvironmentBehavior from '../behaviours/studio-environment';
import MotionPathBehavior from '../behaviours/motion-path';
import MouseBehaviour from '../behaviours/mouse';
import Kaleidocycle from './kaleidocycle';
import { nube as nubeContent } from '../../content';

export default class Application extends Prefab(Scene) {
  set camera(camera) {
    const renderer = this.getBehaviourByType(RendererBehaviour);
    if (!renderer) return;
    renderer.camera = camera;
  }

  get camera() {
    const renderer = this.getBehaviourByType(RendererBehaviour);
    if (!renderer) return null;
    return renderer.camera;
  }

  get domElement() {
    return this.getBehaviourByType(RendererBehaviour).renderer.domElement;
  }

  constructor(props) {
    super(props);

    // Setup
    this.addBehaviour(new RendererBehaviour())
      .addBehaviour(new LoopBehaviour())
      .addBehaviour(new MouseBehaviour())
      /*.addBehaviour(
				new PostProcessingBehaviour({
					profile: [
						new RenderPass(this, this.camera),
						//new SMAAPass(window.Image)
					]
				})
			)*/
      .addBehaviour(new CameraBehaviour())
      .addBehaviour(new StudioEnvironmentBehavior({ radius: 500 }));

    // Nubes
    this.createNubes();

    // Editor
    if (process.env.NODE_ENV === 'development') {
      let editor = null;
      window.enableEditor = async () => {
        const { default: Editor } = await import('../behaviours/editor');
        this.addBehaviour((editor = new Editor()));
        window.addEventListener('keyup', e => {
          if (editor || !(e.ctrlKey && e.key === 'e')) return;
          window.enableEditor();
        });
      };
    }
  }

  createNubes() {
    const r = 200;
    const camR = 80;
    const camRise = 5;
    const max = Object.keys(nubeContent.colors).length;
    const radRange = (Math.PI * 2) / max;
    const camPosPath = new CurvePath();
    const camLookPath = new CurvePath();

    this._nubes = Object.keys(nubeContent.colors).map((colorKey, i) => {
      // Nube
      const nube = new Kaleidocycle(colorKey);
      const rad = radRange * i;
      nube.position.x = Math.cos(rad) * r;
      nube.position.z = Math.sin(rad) * r;
      nube.rotation.y = rad * -1 + Math.PI / 2;
      nube.interactive = i === 0;
      this.add(nube);

      // Camera Movement
      const a = i => radRange * i;
      const vStart = new Vector3(Math.cos(a(i)) * r, 0, Math.sin(a(i)) * r);
      const vEnd = new Vector3(
        Math.cos(a(i + 1)) * r,
        0,
        Math.sin(a(i + 1)) * r,
      );
      const dist = vStart.distanceTo(vEnd);
      const vControl = new Vector3(
        Math.cos(a(i + 0.5)) * (r + dist / max),
        camRise,
        Math.sin(a(i + 0.5)) * (r + dist / max),
      );
      camLookPath.add(new QuadraticBezierCurve3(vStart, vControl, vEnd));

      const offset = 1 + camR / r;
      camPosPath.add(
        new QuadraticBezierCurve3(
          vStart.clone().multiplyScalar(offset),
          vControl.clone().multiplyScalar(offset),
          vEnd.clone().multiplyScalar(offset),
        ),
      );

      // Lights

      let light = new PointLight(0xe5e5e5, 0.6);
      let lightDist = r + 100;
      light.position.set(
        Math.cos(rad) * lightDist,
        -5,
        Math.sin(rad) * lightDist,
      );
      this.add(light);

      /*light = new PointLight(0x8aacb7,.5);
			light.position.set(
				Math.cos(rad-.1) * (r + 50),
				10,
				Math.sin(rad-.1) * (r + 50)
			);
			this.add(light);*/

      return nube;
    });

    // Animations

    const cameraMotionPath = new MotionPathBehavior({
      curve: camPosPath,
      drawLine: false,
    });
    this.addBehaviour(cameraMotionPath);

    const { camera } = this;
    let current = 0;
    const panTo = i => {
      if (i === current) return;

      this._nubes.forEach((n, ni) => {
        n.interactive = i === ni;
      });

      if (this._panAnimation) {
        this._panAnimation.pause();
      }

      this._panAnimation = cameraMotionPath.animation(camera.position, {
        duration: Math.abs(i - current) * 1000,
        easing: 'easeInOutCubic',
        update: p => {
          this._currentLookPathPosition = p;
          const lookAtPoint = camLookPath.getPoint(p);
          //lookAtPoint.x *= 1.1;
          camera.lookAt(lookAtPoint);
        },
        from: this._currentLookPathPosition || current / max,
        to: i / max,
      });

      current = i;

      return this._panAnimation;
    };

    this.panToIndex = i => {
      return panTo(i);
    };

    this.getCurrentColorIndex = () => current;

    camera.position.copy(camPosPath.getPoint(0));
    camera.lookAt(camLookPath.getPoint(0));
  }

  // api
  setColorKey(key) {
    if (!key) throw new Error(`invalid color key "${key}"`);
    if (this._colorKey === key) return;
    this._colorKey = key;
    let i = -1;
    this._nubes.forEach((n, ni) => {
      if (n.colorKey === key) i = ni;
    });
    this.panToIndex(i);
  }

  getColorKey() {
    return this._colorKey;
  }

  getActiveNube() {
    return this.findChild({ colorKey: this._colorKey });
  }

  setSurfaceKey(key) {
    if (!key) throw new Error(`invalid surface key "${key}"`);
    if (this._surfaceKey === key) return;
    this._surfaceKey = key;
    const activeNube = this.getActiveNube();
    this._nubes.forEach(n => {
      n.setSurfaceKey(key, activeNube === n);
    });
  }

  getSurfaceKey() {
    return this._surfaceKey;
  }

  setHighFPSMode(v) {
    this.getBehaviourByType(LoopBehaviour).loop.setFPS(v ? 60 : 16);
  }
}
