import React, { Component } from 'react';
import Sidebar from '../../sidebar';
import OrderForm from './form';
import SurfaceSelection from './surface-selection';
import { DragDropContextProvider } from 'react-dnd';
import TouchBackend from 'react-dnd-touch-backend';
import HTML5Backend from 'react-dnd-html5-backend';
import createMultiBackend from './dnd-multi-backend';
import MediaQuery from 'react-responsive';
import { Header } from '../../layout';
import { header as headerContent } from '../../../content';

const dndBackend = createMultiBackend(HTML5Backend, TouchBackend);

export default class OrderView extends Component {
  static contextTypes = { router: () => null };
  state = { leaving: false };

  handleSuccess = () => {
    this.context.router.history.push('/');
  };

  handleError = () => {
    alert(
      'Ein unbekannter Fehler ist aufgetreten, bitte versuchen Sie es zu einem späteren Zeitpunkt noch ein mal',
    );
    this.context.router.history.push('/');
  };

  render() {
    return (
      <DragDropContextProvider backend={dndBackend}>
        <div className={!this.state.leaving ? 'surface-selection-open' : ''}>
          <MediaQuery query={`(min-width:992px)`}>
            <div>
              <Header
                content={headerContent}
                showMenu={false}
                style={{ zIndex: 200, width: '300px' }}
              />
              <SurfaceSelection />
            </div>
          </MediaQuery>
          <Sidebar
            open={!!this.props.active}
            onLeave={() => this.setState({ leaving: true })}
            onClose={() => this.setState({ leaving: false })}
            closeOnBackdrop={false}
            size={9}
          >
            <article className="nube-page-content">
              <OrderForm
                onSuccess={this.handleSuccess}
                onError={this.handleError}
              />
            </article>
          </Sidebar>
        </div>
      </DragDropContextProvider>
    );
  }
}
