import { WebGLRenderer, PerspectiveCamera } from 'three/build/three.module';
export default class RendererBehaviour {
  start() {
    this.renderer = new WebGLRenderer();
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.target = this.parent;
    this.camera = new PerspectiveCamera(
      45,
      this.parent.clientWidth / this.parent.clientHeight,
      1,
      2000,
    );

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);
  }

  stop() {
    window.removeEventListener('resize', this.handleResize);
  }

  appendTo(node) {
    node.appendChild(this.renderer.domElement);
    this.handleResize();
  }

  handleResize() {
    this.fitParent();
    const { clientWidth, clientHeight } = this.renderer.domElement;
    if (this.parent) this.parent.fire('resize', clientWidth, clientHeight);
  }

  fitParent() {
    const { camera, renderer } = this;
    const { parentNode } = renderer.domElement;

    if (!parentNode) return;

    renderer.setSize(parentNode.clientWidth, parentNode.clientHeight);

    if (camera) {
      camera.aspect = parentNode.clientWidth / parentNode.clientHeight;
      camera.updateProjectionMatrix();
    }

    this.render();
  }

  render() {
    if (this.target && this.camera)
      this.renderer.render(this.target, this.camera);
  }
}
