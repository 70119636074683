import React, { Component } from 'react';
import { withFormsy as HOC } from 'formsy-react';
import * as FormControl from './index';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { omit } from '../../util';

const formsyPropNames = [
  `validators`,
  `setValidations`,
  `setValue`,
  `resetValue`,
  `value`,
  `hasValue`,
  `errorMessage`,
  `errorMessages`,
  `isFormDisabled`,
  `isValid`,
  `isPristine`,
  `isFormSubmitted`,
  `isRequired`,
  `showRequired`,
  `showError`,
  `isValidValue`,
  `validationError`,
  `validationErrors`,
  `innerRef`
];

export const HOCInput = (InputComponent, isNative = true) => {
  return HOC(
    class FormsyInput extends Component {
      handleInputChange = eventOrValue => {
        if (isNative) {
          if(eventOrValue.currentTarget.type === 'checkbox'){
            this.props.setValue(Boolean(eventOrValue.currentTarget.checked));
          }else{
            this.props.setValue(eventOrValue.currentTarget.value);
          }
        } else {
          this.props.setValue(eventOrValue);
        }
      };

      render() {
        const props = this.props;
        const {value} = props;
        const inputProps = omit(props, ...formsyPropNames);
        return (
          <InputComponent
            {...inputProps}
            value={isNative?(value||''):value}
            onChange={this.handleInputChange}
          />
        );
      }
    },
  );
};

export const HOCComp = InputComponent => HOCInput(InputComponent, false);

export const Input = HOCInput(FormControl.Input);
export const Checkbox = HOCInput(FormControl.Checkbox);
export const Radio = HOCInput(FormControl.Radio);
export const Textarea = HOCInput(FormControl.Textarea);
export const Select = HOCComp(ReactSelect);
export const CheckboxGroup = HOCComp(FormControl.CheckboxGroup);
export const RadioGroup = HOCComp(FormControl.RadioGroup);

export const HOCFormGroup = (InputComponent, isNative = true) => {
  return HOC(
    class FormsyInput extends Component {
      handleInputChange = eventOrValue => {
        if (isNative) {
          if(eventOrValue.currentTarget.type === 'checkbox'){
            this.props.setValue(Boolean(eventOrValue.currentTarget.checked));
          }else{
            this.props.setValue(eventOrValue.currentTarget.value);
          }
        } else {
          this.props.setValue(eventOrValue);
        }
      };

      render() {
        const props = Object.assign({}, this.props);
        const {value} = props;
        const showError = !props.isValid && !props.isPristine;
        const stateClassNames = {
          'is-invalid': showError,
        };
        const {errorMessage} = props;
        let { label } = props;
        if (typeof label === 'string')
          label = <label htmlFor={props.id}>{label}</label>;

        const inputProps = omit(
          props,
          ...formsyPropNames.concat(['formGroupClassName', 'label']),
        );
        return (
          <div
            className={classNames(
              props.formGroupClassName || 'form-group',
              stateClassNames,
            )}
          >
            {label}
            <InputComponent
              {...inputProps}
              value={isNative?(value||''):value}
              onChange={this.handleInputChange}
            />
            {showError && (
              <div className="form-text">
                {errorMessage || 'dies ist ein Pflichtfeld'}
              </div>
            )}
          </div>
        );
      }
    },
  );
};

export const HOCFormGroupComp = InputComponent =>
  HOCFormGroup(InputComponent, false);

export const FormGroup = {
  Input: HOCFormGroup(FormControl.Input),
  Checkbox: HOCFormGroup(FormControl.Checkbox),
  Radio: HOCFormGroup(FormControl.Radio),
  Textarea: HOCFormGroup(FormControl.Textarea),
  Select: HOCFormGroupComp(ReactSelect),
  CheckboxGroup: HOCFormGroupComp(FormControl.CheckboxGroup),
  RadioGroup: HOCFormGroupComp(FormControl.RadioGroup),
};
