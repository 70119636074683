import { Vector3, Face3 } from 'three/build/three.module';

export default (t, h, n, pa, pb, qc, qd) => {
  // This is where the magic happens.
  // The math was all done by someone else, see here: http://www.kaleidocycles.de/theory.shtml
  // t = theta = the angle of rotation of the tetrahedron
  // h = distance betwen pq = scale factor of individual tetrahedron
  // n = number of tetrahedrons in cycle; must be even.
  // pa, pb = offsets for points a,b from point p
  // qc, qd = offsets for points c,d from point q
  var u, v, w;
  var alpha, ct, st, ta;
  t = (Math.PI * t) / 180;
  if (pa === 0) {
    pa = 0.0001;
  }
  if (qc === 0) {
    qc = 0.0001;
  }
  alpha = (Math.PI * 2) / n;
  ct = Math.cos(t);
  st = Math.sin(t);
  ta = Math.tan(alpha);
  u = new Vector3(ct, 0, st);
  v = new Vector3(-st, -st * ta, ct).multiplyScalar(
    1 / Math.sqrt(1 + st * st * ta * ta),
  );
  w = new Vector3();
  w.crossVectors(u, v).negate();
  var P, Q;
  P = new Vector3(w.y / ta - w.x, 0, -w.z / 2).multiplyScalar(h);
  Q = new Vector3(w.y / ta, w.y, w.z / 2).multiplyScalar(h);
  var A, B, C, D;
  A = new Vector3();
  A.subVectors(P, u.multiplyScalar(pa));
  u.divideScalar(pa);
  B = new Vector3();
  B.addVectors(P, u.multiplyScalar(pb));
  u.divideScalar(pb);
  C = new Vector3();
  C.subVectors(Q, v.multiplyScalar(qc));
  v.divideScalar(qc);
  D = new Vector3();
  D.addVectors(Q, v.multiplyScalar(qd));
  var vertices = [A, B, C, D];
  var faces = [];
  for (var i = 0; i < n / 2; i++) {
    var Ai = i * 4,
      Bi = i * 4 + 1,
      Ci = i * 4 + 2,
      Di = i * 4 + 3;
    var Ai2 = (i * 4 + 4) % (n * 2),
      Bi2 = (i * 4 + 5) % (n * 2);
    faces.push(
      new Face3(Ai, Ci, Bi),
      new Face3(Ai, Di, Ci),
      new Face3(Ai, Bi, Di),
      new Face3(Bi, Ci, Di),
      new Face3(Ci, Ai2, Bi2),
      new Face3(Ci, Di, Ai2),
      new Face3(Bi2, Ai2, Di),
      new Face3(Ci, Bi2, Di),
    );
  }
  const sideIndexes = [0, 0, 0, 0];
  for (i = 0; i < faces.length; i++) {
    const side = i % 4;
    faces[i].materialIndex = sideIndexes[side]++;
  }
  const z_axis = new Vector3(0, 0, 1);
  for (i = 1; i < Math.floor(n / 2); i++) {
    var A1 = new Vector3();
    A1.copy(A);
    A1.applyAxisAngle(z_axis, i * 2 * alpha);
    var B1 = new Vector3();
    B1.copy(B);
    B1.applyAxisAngle(z_axis, i * 2 * alpha);
    var C1 = new Vector3();
    C1.copy(C);
    C1.applyAxisAngle(z_axis, i * 2 * alpha);
    var D1 = new Vector3();
    D1.copy(D);
    D1.applyAxisAngle(z_axis, i * 2 * alpha);
    vertices.push(A1, B1, C1, D1);
  }
  v.divideScalar(qd);

  return [vertices, faces];
};
