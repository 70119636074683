import React, { Component } from 'react';
import { nube } from '../../../content';
import { Container, Row, Col } from '../../grid';
import classNames from 'classnames';
import { DropTarget, DragSource } from 'react-dnd';

const { surfaces, colors } = nube;
const surfaceKeys = Object.keys(surfaces);
const colorKeys = Object.keys(colors);

const surfaceSource = {
  beginDrag({ surfaceKey, colorKey, nuanceIndex }) {
    return {
      surfaceKey,
      colorKey,
      nuanceIndex,
    };
  },
};

@DragSource('SURFACE', surfaceSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))
class Surface extends Component {
  componentDidMount() {
    selectionManager.subscribe(this.handleChange);
  }

  componentWillUnmount() {
    selectionManager.unsubscribe(this.handleChange);
  }

  handleChange = () => this.forceUpdate();

  render() {
    const {
      connectDragSource,
      src,
      title,
      surfaceKey,
      colorKey,
      nuanceIndex,
    } = this.props;
    const isSelected =
      selectionManager.selection.indexOf(
        getUUID({ surfaceKey, colorKey, nuanceIndex }),
      ) > -1;
    return connectDragSource(
      <div
        className={classNames('nube-surface-selection__surface-tile', {
          isSelected,
        })}
      >
        <img className="fluid" src={src} alt={title} />
        <div>
          <span>{title}</span>
        </div>
      </div>,
    );
  }
}

export const Surfaces = ({ surfaceKey, colorKey }) => {
  const surface = surfaces[surfaceKey];
  const nuances = surface.nuances[colorKey];
  return (
    <Row>
      {nuances.map((nuance, nuanceIndex) => {
        const low = nuance.filename.split('.jpg').join('.lowres.sm.jpg');
        const src = `/img/textures/surfaces/${colorKey}/${surfaceKey}/${low}`;

        return (
          <Col
            key={nuanceIndex}
            xs={12}
            md={24}
            className="nube-surface-selection__surface"
          >
            <Surface
              src={src}
              title={nuance.title}
              {...{ surfaceKey, colorKey, nuanceIndex }}
            />
          </Col>
        );
      })}
    </Row>
  );
};

export const Color = ({ colorKey }) => {
  const { title } = colors[colorKey];
  let rsf5Start = true;
  return (
    <Row>
      <Col xs={24} style={{marginBottom:'-4em'}}>
        <div className="nube-surface-selection__color-heading">{title}</div>
      </Col>

      {surfaceKeys.map(surfaceKey => {
        const surface = surfaces[surfaceKey];
        const rsf5 = surface.title.includes('RSF5');
        let classList = [];

        if(rsf5) {
          if(rsf5Start){
            rsf5Start = false;
            classList.push('rsf5-start');
          }
          classList.push('rsf5');
        }

        return (
          <Col
            xl={`1-10`}
            lg={`2-10`}
            style={{marginTop:'4em'}}
            md={Math.floor(48 / surfaceKeys.length)}
            key={surfaceKey}
            className={classNames(classList)}
          >
            <Surfaces surfaceKey={surfaceKey} colorKey={colorKey} />
          </Col>
        );
      })}
    </Row>
  );
};

export default class SurfaceSelection extends React.Component {
  render() {
    return (
      <Container className="nube-surface-selection">
        {colorKeys.map(colorKey => {
          return <Color colorKey={colorKey} key={colorKey} />;
        })}
        <Row>
          <Col xs={24}>
            <p className="nube-surface-selection__disclaimer">{`Mindestbestellmenge im Auftragsfall 400 m² pro Format und Farbe.`}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

const DropZoneItem = ({ onClick, surfaceKey, colorKey, nuanceIndex }) => {
  const nuance = surfaces[surfaceKey].nuances[colorKey][nuanceIndex];
  const low = nuance.filename.split('.jpg').join('.lowres.sm.jpg');
  const src = `/img/textures/surfaces/${colorKey}/${surfaceKey}/${low}`;
  let title = nuance.title.split(' ');
  if (title.length > 3) {
    title[1] += '<br />';
  }
  title = title.join(' ');

  return (
    <li {...{ onClick }}>
      <img src={src} alt={nuance.title} />
      <div>
        <span dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <span className="icon-cross" />
    </li>
  );
};

const dropZoneSpec = {
  drop(connect, monitor, comp) {
    comp.handleDrop(monitor.getItem());

    return {
      name: 'DropZone',
    };
  },
};

export const getUUID = ({ surfaceKey, colorKey, nuanceIndex }) => {
  if (!surfaceKey) throw new Error('invalid surfaceKey');

  return `${surfaceKey},${colorKey},${nuanceIndex}`;
};

export const getUUIDItem = uuid => {
  const [surfaceKey, colorKey, nuanceIndex] = uuid.split(',');
  return Object.assign(
    { surfaceKey, colorKey, nuanceIndex },
    surfaces[surfaceKey].nuances[colorKey][+nuanceIndex],
  );
};

@DropTarget('SURFACE', dropZoneSpec, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))
class DropZone extends Component {
  componentDidMount() {
    this.componentWillReceiveProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const value = nextProps.value ? [...nextProps.value] : [];
    selectionManager.update(value);
  }

  handleDrop(item) {
    const uuid = getUUID(item);
    const value = this.props.value || [];
    const { onChange } = this.props;
    if (value.indexOf(uuid) === -1 && onChange) {
      const nv = selectionManager.update([...value, uuid]);
      onChange(nv);
    }
  }

  handleItemClick(uuid) {
    const value = this.props.value || [];
    const { onChange } = this.props;
    const index = value.indexOf(uuid);
    if (index !== -1 && onChange) {
      const newValue = value.slice(0);
      newValue.splice(index, 1);
      onChange(selectionManager.update(newValue));
    }
  }

  render() {
    const { connectDropTarget, isOver, canDrop, value } = this.props;
    const isActive = isOver && canDrop;
    const selection = value || [];

    return connectDropTarget(
      <div
        className={classNames('nube-surface-selection-dropzone', { isActive })}
      >
        {isActive && !selection.length && (
          <p className="affirm">Hier loslassen um auszuwählen</p>
        )}
        {!isActive && !selection.length && (
          <p className="help">
            Drag & Drop: Ziehen Sie bis zu 4 Muster hierher.
          </p>
        )}
        <ul className="nube-surface-selection-dropzone__items">
          {selection.map((uuid, key) => {
            return (
              <DropZoneItem
                onClick={e => this.handleItemClick(uuid)}
                key={key}
                {...getUUIDItem(uuid)}
              />
            );
          })}
        </ul>
      </div>,
    );
  }
}

export const selectionManager = {
  listeners: [],
  selection: [],
  maxSize: 4,
  update(selection = [], write = true) {
    selection = [...selection];

    if (selection.length > this.maxSize) {
      selection = selection
        .reverse()
        .slice(0, this.maxSize)
        .reverse();
    }

    if (write) {
      this.write(selection);
    }

    return selection;
  },
  write(selection = []) {
    this.selection = selection;
    this.listeners.forEach(listener => {
      listener([...selection], this);
    });
  },
  read() {
    return [...this.selection];
  },
  reset() {
    this.update();
  },
  subscribe(listener) {
    this.listeners.push(listener);
  },
  unsubscribe(listener) {
    const index = this.listeners.indexOf(listener);
    if (index > -1) this.listeners.splice(index, 1);
  },
};

export { DropZone, Surface };
