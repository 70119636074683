import React from 'react';
import classNames from 'classnames';
import { omit } from '../util';

export default props => {
  const classes = {
    btn: true,
    sm: props.size === 'sm',
    lg: props.size === 'lg',
    [props.variant]: !!props.variant,
  };

  const Component = props.component || 'button';

  return (
    <Component
      {...omit(props, 'size', 'variant', 'component')}
      className={classNames(classes)}
    />
  );
};
