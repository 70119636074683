import React, { Component } from 'react';
import {
  DropZone,
  getUUID,
  getUUIDItem,
  selectionManager,
} from './surface-selection';
import MediaQuery from 'react-responsive';
import ReactSelect from 'react-select';
import Form from 'formsy-react';
import { FormGroup, HOCFormGroupComp } from '../../form-control/formsy';
import { Submit } from '../../form-control/index';
import { Row, Col } from '../../grid';
import { nube, countries, strings } from '../../../content';
const { Input, CheckboxGroup, Checkbox, Textarea, Select } = FormGroup;
const { colors, surfaces } = nube;
const countryList = Object.keys(countries).map(value => ({
  label: countries[value],
  value,
}));

const nubeOptionList = () => {
  const list = [];
  Object.keys(colors).forEach(colorKey => {
    Object.keys(surfaces).forEach(surfaceKey => {
      surfaces[surfaceKey].nuances[colorKey].forEach((nuance, nuanceIndex) => {
        list.push({
          surfaceKey,
          colorKey,
          nuanceIndex,
          value: nuance.title,
          label: nuance.title,
          title: nuance.title,
        }); //{label:nuance.title,value:,thumbnailPath,color:colors[colorKey].color});
      });
    });
  });

  return list;
};

const FormRow = props => {
  const children = React.Children.toArray(props.children);
  return (
    <Row className="form-section" {...props}>
      {children.map((child, index) => (
        <Col xs={24} sm={Math.floor(24 / children.length)} key={index}>
          {child}
        </Col>
      ))}
    </Row>
  );
};

class DropZoneAdapter extends Component {
  handleChange = (value = []) => {
    if (!this.props.onChange) return;
    this.props.onChange(value.map(uuid => getUUIDItem(uuid)));
  };

  adaptValue = props => {
    let { value } = props;
    value = value || [];
    return value.map(item => getUUID(item));
  };

  render() {
    return (
      <DropZone
        {...this.props}
        onChange={this.handleChange}
        value={this.adaptValue(this.props)}
      />
    );
  }
}

const DropZoneInput = HOCFormGroupComp(DropZoneAdapter);

class MultiSelectAdapter extends Component {
  handleChange = (value = []) => {
    if (!this.props.onChange) return;
    const clampedValue =
      value.length > selectionManager.maxSize
        ? value
            .reverse()
            .slice(0, selectionManager.maxSize)
            .reverse()
        : value.slice(0);
    this.props.onChange(clampedValue);
  };

  adaptValue = ({ value = [] }) => {
    return value;
  };

  render() {
    return (
      <ReactSelect
        {...this.props}
        onChange={this.handleChange}
        value={this.adaptValue(this.props)}
      />
    );
  }
}

const MultiSelect = HOCFormGroupComp(MultiSelectAdapter);

export default class OrderForm extends Component {
  state = {
    isValid: false,
    isSending: false,
  };

  renderSurfaceOption(item) {
    const uuid = getUUID(item);
    const { surfaceKey, colorKey } = item;
    const nuance = getUUIDItem(uuid);
    const thumbnail = nuance.filename.split('.jpg').join('.lowres.xs.jpg');
    const thumbnailPath = `/img/textures/surfaces/${colorKey}/${surfaceKey}/${thumbnail}`;

    return (
      <div className="nube-surface-option">
        <div>
          <span style={{ backgroundColor: colors[colorKey].color }} />
          <img alt={nuance.title} src={thumbnailPath} />
          <div>{nuance.title}</div>
        </div>
      </div>
    );
  }

  handleSubmit = data => {
    this.setState({ isSending: true });
    fetch('/order.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => {
      this.setState({ isSending: false, canSubmit: false });
      this.refs.form.reset();
      if (res.status === 200 && this.props.onSuccess) {
        this.props.onSuccess(data);
      }
      if (res.status >= 400 && this.props.onError) {
        this.props.onError(data);
      }
    });
  };

  render() {
    return (
      <Form
        disabled={this.state.isSending}
        ref="form"
        onValidSubmit={this.handleSubmit}
        onValid={() => this.setState({ isValid: true })}
        onInvalid={() => this.setState({ isValid: false })}
      >
        <FormRow style={{ marginTop: '30px' }}>
          <h2>nube Musterbestellung</h2>
        </FormRow>
        <FormRow>
          <h3 className="form-section-head">Muster wählen *</h3>
        </FormRow>

        <MediaQuery query={`(min-width:992px)`}>
          <FormRow>
            <DropZoneInput name="products" />
          </FormRow>
        </MediaQuery>

        <MediaQuery query={`(max-width:991px)`}>
          <FormRow>
            <div>
              <MultiSelect
                multi
                name="products"
                placeholder={`Muster durchsuchen...`}
                resetValue={[]}
                optionRenderer={this.renderSurfaceOption}
                options={nubeOptionList()}
              />
              <p className="text-small text-muted">
                Bitte wählen Sie bis zu vier Muster aus.
              </p>
            </div>
          </FormRow>
        </MediaQuery>

        <FormRow>
          <h3 className="form-section-head">Projekt</h3>
        </FormRow>
        <FormRow>
          <Input
            resetValue=""
            name="projectName"
            required
            placeholder="Bezeichnung *"
          />
        </FormRow>
        <FormRow>
          <Input
            resetValue=""
            name="projectCity"
            required
            placeholder="Ort *"
          />
        </FormRow>
        <FormRow>
          <h3 className="form-section-head">Bedarf</h3>
        </FormRow>
        <FormRow>
          <CheckboxGroup
            resetValue=""
            formGroupClassName="radio-formgroup"
            name="demand"
            items={[
              'Bodenbelag',
              'Möblierung',
              'Treppen',
              'Oberflächenschutz',
            ]}
            inline={true}
          />
        </FormRow>

        <FormRow>
          <h3 className="form-section-head">Persönliche Daten</h3>
        </FormRow>

        <FormRow>
          <Input resetValue="" name="firstname" placeholder="Vorname" />
          <Input
            resetValue=""
            name="lastname"
            placeholder="Nachname *"
            required
          />
        </FormRow>

        <FormRow>
          <Input resetValue="" name="company" placeholder="Firma" />
        </FormRow>

        <FormRow>
          <Input resetValue="" name="street" placeholder="Straße *" required />
        </FormRow>

        <FormRow>
          <Input
            resetValue=""
            name="zip"
            placeholder="Postleitzahl *"
            required
          />
          <Input resetValue="" name="city" placeholder="Ort *" required />
        </FormRow>

        <FormRow>
          <Select
            name="country"
            placeholder="Land *"
            required
            options={countryList}
          />
        </FormRow>

        <FormRow>
          <Input
            resetValue=""
            name="email"
            placeholder="E-Mail *"
            validations="isEmail"
            validationError="Bitte geben sie eine E-Mail Adresse an"
            required
          />
          <Input name="phone" placeholder="Telefon" />
        </FormRow>

        <FormRow>
          <Textarea
            resetValue=""
            name="message"
            rows={8}
            placeholder="Ihre Anmerkungen"
          />
        </FormRow>
        <FormRow>
          <Checkbox
            name="privacy-policy"
            required
            inline
            caption={
              <span>
                Ich habe die{' '}
                <a
                  href="https://www.rinn.net/datenschutz.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Datenschutzerklärung
                </a>{' '}
                gelesen und akzeptiert *
              </span>
            }
          />
        </FormRow>

        <FormRow>
          <p
            className="text-small text-muted"
            style={{ margin: '0px 0px 15px 0' }}
          >
            {`* = Pflichtfeld`}
            <br />
            <br />
            {strings['order-disclaimer']}
          </p>
        </FormRow>

        <Row>
          <Col sm={24} className="text-right">
            <Submit variant={this.state.isValid ? 'primary' : 'secondary'}>
              Jetzt bestellen
            </Submit>
          </Col>
        </Row>
      </Form>
    );
  }
}
