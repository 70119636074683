import React, { Component } from 'react';
import { Header } from '../layout';
import * as content from '../../content';
import classNames from 'classnames';
import Icon from '../icon';
import { Link } from '../router';
import Loader from '../loader';
import { flattenedNuances } from './surfaces';
import { omit } from '../../util';

const TransitionLink = (props, { router }) => {
  return (
    <Link
      {...omit(props, 'timeout', 'onLeave', 'to')}
      onClick={e => {
        e.preventDefault();
        setTimeout(() => {
          const { history } = router;
          const { to } = props;
          if (history.location.pathname === to) return;
          history.push(to);
        }, props.timeout);
        props.onLeave && props.onLeave(e);
      }}
    />
  );
};
TransitionLink.contextTypes = { router: () => null };

export default class DetailView extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    loaded: false,
    leaving: false,
    src: null,
    title: null,
  };

  componentDidMount() {
    this.updateContent(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.updateContent(nextProps);
  }

  updateContent(props) {
    const { nuanceIndex, surfaceKey, colorKey } = props.params || {};
    const { filename, title } =
      content.nube.surfaces[surfaceKey].nuances[colorKey][+nuanceIndex] || {};
    const low = filename ? filename.split('.jpg').join('.lowres.jpg') : null;
    const src = low
      ? `/img/textures/surfaces/${colorKey}/${surfaceKey}/${low}`
      : null;
    this.setState({ loaded: false, src, title, leaving: false });
    if (src) {
      const img = new Image();
      img.onload = () => this.setState({ loaded: true });
      img.src = src;
    }
  }

  clamp = index => Math.max(Math.min(index, this.getLength() - 1), 0);

  getIndexOf(params) {
    const { colorKey, surfaceKey, nuanceIndex } = params || this.props.params;
    for (let i = 0; i < flattenedNuances[colorKey].length; ++i) {
      const nuance = flattenedNuances[colorKey][i];
      if (nuance.index === +nuanceIndex && nuance.surfaceKey === surfaceKey)
        return i;
    }

    return 0;
  }

  getLength(params = this.props.params) {
    return flattenedNuances[params.colorKey].length;
  }

  hasNext = () => {
    return this.getIndexOf() < this.getLength() - 1;
  };

  hasPrev = () => {
    return this.getIndexOf() > 0;
  };

  nextUri = () => {
    const index = this.clamp(this.getIndexOf() + 1);
    const { colorKey } = this.props.params;
    const nuance = flattenedNuances[colorKey][index];
    if (index === this.getIndexOf()) return null;
    return `/detail/${colorKey}/${nuance.surfaceKey}/${nuance.index}`;
  };

  prevUri = () => {
    const index = this.clamp(this.getIndexOf() - 1);
    const { colorKey } = this.props.params;
    const nuance = flattenedNuances[colorKey][index];
    if (index === this.getIndexOf()) return null;
    return `/detail/${colorKey}/${nuance.surfaceKey}/${nuance.index}`;
  };

  render() {
    const { src, title, loaded, leaving } = this.state;
    const { surfaceKey, colorKey } = this.props.params || {};

    return (
      <div>
        <Loader active={!loaded} delay={false} />
        <div
          className={classNames('nube-nuance-detail', {
            leaving,
            loading: !loaded,
          })}
          style={{ backgroundImage: src ? `url(${src})` : 'none' }}
        >
          <Header content={content.header} showMenu={false} />
          <h1>{title}</h1>
          <TransitionLink
            className="closebtn"
            to={`/${colorKey}/${surfaceKey}`}
            component="button"
            onLeave={() => this.setState({ leaving: true })}
            timeout={300}
          >
            <Icon which="cross" />
          </TransitionLink>
          <TransitionLink
            className={classNames('nextbtn', { disabled: !this.hasNext() })}
            to={this.nextUri()}
            onLeave={() => this.setState({ leaving: true })}
            timeout={300}
          >
            <Icon which="arrow-right" />
          </TransitionLink>

          <TransitionLink
            className={classNames('prevbtn', { disabled: !this.hasPrev() })}
            to={this.prevUri()}
            onLeave={() => this.setState({ leaving: true })}
            timeout={300}
          >
            <Icon which="arrow-left" />
          </TransitionLink>
        </div>
      </div>
    );
  }
}
