import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { pages, nube } from '../../content';
import { Row, Col } from '../grid';
import { Link } from '../router';
const { surfaces, colors } = nube;
const surfaceKeys = Object.keys(surfaces);
const colorKeys = Object.keys(colors);

export const ColorPaletteSurface = ({ surfaceKey, colorKey }) => {
  const surface = surfaces[surfaceKey];
  const nuances = surface.nuances[colorKey];
  return (
    <Row>
      <Col xs={24}>
        <h3 className="surface-heading">
          <span>{surface.title}</span>
          <p>{surface.description}</p>
        </h3>
      </Col>
      {nuances.map((nuance, key) => {
        const low = nuance.filename.split('.jpg').join('.lowres.sm.jpg');
        const src = `/img/textures/surfaces/${colorKey}/${surfaceKey}/${low}`;

        return (
          <Col key={key} xs={12} md={24}>
            <Link
              to={`/detail/${colorKey}/${surfaceKey}/${key}`}
              title={nuance.title}
            >
              <img className="fluid" src={src} alt={nuance.title} />
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};

export const ColorPaletteColor = ({ colorKey }) => {
  const { color, title } = colors[colorKey];
  return (
    <Row>
      <Col xs={24}>
        <div className="color-heading" style={{ backgroundColor: color }}>
          {title}
        </div>
      </Col>
      <Col xs={24}>
        <Row className="small-grap">
        {surfaceKeys.map(surfaceKey => {
          return (
            <Col
              xl={`1-10`}
              lg={Math.floor(48 / surfaceKeys.length)}
              key={surfaceKey}
            >
              <ColorPaletteSurface surfaceKey={surfaceKey} colorKey={colorKey} />
            </Col>
          );
        })}
        </Row>
      </Col>
    </Row>
  );
};

export const ColorPalette = () => {
  return (
    <div className="nube-colorpalette">
      {colorKeys.map(colorKey => {
        return <ColorPaletteColor colorKey={colorKey} key={colorKey} />;
      })}
    </div>
  );
};

export default class ColorPaletteView extends Component {
  render() {
    const { active } = this.props.params || {};
    return (
      <Sidebar open={!!active} size={22}>
        <article className="nube-page-content">
          <div
            className="article"
            dangerouslySetInnerHTML={{ __html: pages['farbpalette'] }}
          />
          <ColorPalette />
          <a
            href="/content/nube_farben_flyer.pdf"
            className="primary pull-right"
          >
            Farbpalette als PDF
          </a>
          <div className="clearfix" />
        </article>
      </Sidebar>
    );
  }
}
