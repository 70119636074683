import React from 'react';
import ReactDOM from 'react-dom';
import { nube as colorPalette } from '../content';
import classNames from 'classnames';
import { Link } from './router';
import { omit } from '../util';

class ColorLink extends React.Component {
  state = {
    hover: false,
  };

  handleOver = () => this.setState({ hover: true });
  handleOut = () => this.setState({ hover: false });

  getStyle() {
    const { hover } = this.state;
    const { active, color, activeColor, hoverColor } = this.props;
    let backgroundColor = color;

    if (hover) {
      backgroundColor = hoverColor;
    } else if (active) {
      backgroundColor = activeColor;
    }

    return { backgroundColor };
  }

  render() {
    return (
      <Link
        {...omit(this.props, 'active', 'activeColor', 'hoverColor', 'color')}
        style={this.getStyle()}
        onMouseOver={this.handleOver}
        onMouseOut={this.handleOut}
      />
    );
  }
}

export class SurfaceNavigation extends React.Component {
  state = {
    hitareaHeight: 0,
    listHeight: 0,
    hasMouse: true,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({
      hitareaHeight: ReactDOM.findDOMNode(this.refs.hitarea).clientHeight,
      listHeight: ReactDOM.findDOMNode(this.refs.list).clientHeight,
    });
  };

  render() {
    const { colors, surfaces } = this.props.colorPalette || colorPalette;
    const { colorKey, selectedKey } = this.props;
    const { hasMouse, hitareaHeight, listHeight } = this.state;

    const hasNuances = surfaceKey =>
      surfaces[surfaceKey].nuances[colorKey] &&
      surfaces[surfaceKey].nuances[colorKey].length;
    const nuanceKeys = Object.keys(surfaces).filter(hasNuances);
    let translateY = hitareaHeight / 2 - listHeight / 2;

    return (
      <div
        className={classNames('nube-color-nav__surface-hitarea', {
          mouse: hasMouse,
        })}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        ref="hitarea"
      >
        <ul
          className={classNames('nube-color-nav__surface-nav')}
          style={{ transform: `translateY(${translateY}px)` }}
          ref="list"
        >
          {nuanceKeys.map((surfaceKey, i) => {
            const { title, navigationDescription } = surfaces[surfaceKey];
            const active = selectedKey === surfaceKey;
            const opacity = 1;
            const { color } = colors[colorKey];
            const backgroundColor = active && 0 ? color : 'transparent';

            // onClick={()=>{onSelect&&onSelect(surfaceKey)}}
            return (
              <li
                key={`${colorKey}-${surfaceKey}`}
                className={classNames({ active })}
                style={{ opacity, backgroundColor }}
              >
                <Link to={`/${colorKey}/${surfaceKey}`}>{title}</Link>
                <p>{navigationDescription}</p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default class ColorNavigation extends React.Component {
  handleSelectColor = colorKey => {
    const { selectedSurfaceKey } = this.props;
    this.dispatchOnSelect(colorKey, selectedSurfaceKey);
  };

  handleSelectSurface = surfaceKey => {
    const { selectedColorKey } = this.props;
    this.dispatchOnSelect(selectedColorKey, surfaceKey);
  };

  dispatchOnSelect(...props) {
    if (this.props.onSelect) this.props.onSelect(...props);
  }

  render() {
    const { selectedColorKey, selectedSurfaceKey } = this.props;
    const { colors } = this.props.colorPalette || colorPalette;
    let offset = 0;

    const items = Object.keys(colors).map((colorKey, i) => {
      const { title, color, faceBColor } = colors[colorKey];
      const active = selectedColorKey === colorKey;
      if (active) offset = i;
      //Link onClick={()=>this.handleSelectColor(colorKey)}
      return (
        <li key={colorKey} className={classNames({ active })}>
          <ColorLink
            to={`/${colorKey}/${selectedSurfaceKey}`}
            active={active}
            color={color}
            hoverColor={faceBColor}
            activeColor={faceBColor}
          >
            {title}
          </ColorLink>
        </li>
      );
    });

    offset = Math.floor(items.length / 2);

    return (
      <nav id="nube-color-nav" className={this.props.in ? 'in' : null}>
        <ul
          className={classNames('nube-color-nav__color-nav')}
          style={{ transform: `translateY(-${offset * 75}px)` }}
        >
          {items}
        </ul>
        <SurfaceNavigation
          colorPalette={colorPalette}
          onSelect={this.handleSelectSurface}
          selectedKey={selectedSurfaceKey}
          colorKey={selectedColorKey}
          topOffset={0}
        />
      </nav>
    );
  }
}
