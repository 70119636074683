import React from 'react';
import classNames from 'classnames';
import Button from '../button';
import { omit } from '../../util';

export const Submit = props => {
  const Component = props.component || Button;

  return <Component {...omit(props, 'component')} type="submit" />;
};

export const Input = props => {
  const Component = props.component || 'input';

  return (
    <Component
      {...omit(props, 'component')}
      className={classNames(props.className, 'form-control')}
    />
  );
};

export const Select = props => {
  const Component = props.component || 'select';

  return (
    <Component
      {...omit(props, 'component')}
      className={classNames(props.className, 'form-control')}
    />
  );
};

export const Textarea = props => {
  const Component = props.component || 'textarea';

  return (
    <Component
      {...omit(props, 'component')}
      className={classNames(props.className, 'form-control')}
    />
  );
};

export const Checkbox = props => {
  const { inputClassName, labelClassName, className, inline } = props;
  const Component = props.component || 'input';
  const inputProps = omit(
    props,
    'caption',
    'inputClassName',
    'labelClassName',
    'className',
    'inline',
  );

  return (
    <div
      className={classNames(
        className || 'form-check',
        inline && 'form-check-inline',
      )}
    >
      <label className={classNames(labelClassName || 'form-check-label')}>
        <Component
          className={classNames(inputClassName || 'form-check-input')}
          type="checkbox"
          {...inputProps}
        />
        {props.caption || props.label || props.value}
      </label>
    </div>
  );
};

export const Radio = props => {
  return <Checkbox type="radio" {...props} />;
};

export class CheckboxGroup extends React.Component {
  state = {
    value: undefined,
  };

  isChecked = ({ value }) => {
    const type = this.props.type || 'checkbox';
    return (
      (type === 'radio' && this.state.value === value) ||
      (Array.isArray(this.state.value) && this.state.value.indexOf(value) > -1)
    );
  };

  handleItemChange = e => {
    const { currentTarget } = e;
    const type = this.props.type || 'checkbox';
    if (type === 'radio') {
      if (currentTarget.checked) {
        this.dispatchOnChange(currentTarget.value);
      }
    } else {
      const currentValue = (this.state.value || []).slice(0);
      const targetValue = currentTarget.value;
      const index = currentValue.indexOf(targetValue);
      if (currentTarget.checked && index < 0) {
        currentValue.push(targetValue);
      } else if (!currentTarget.checked && index > -1) {
        currentValue.splice(index, 1);
      }
      this.dispatchOnChange(currentValue);
    }
  };

  dispatchOnChange(value) {
    this.setState({ value });
    if (this.props.onChange) this.props.onChange(value);
  }

  render() {
    const items = this.props.items || [];

    return (
      <div
        className={classNames(this.props.groupClassName || 'form-check-group')}
      >
        {items.map((item, key) => {
          const itemProps = omit(this.props, 'items', 'groupClassName');

          item = typeof item === 'string' ? { value: item } : item;
          return (
            <Checkbox
              key={key}
              {...itemProps}
              {...item}
              checked={this.isChecked(item)}
              onChange={this.handleItemChange}
            />
          );
        })}
      </div>
    );
  }
}

export const RadioGroup = props => {
  return <CheckboxGroup {...props} type="radio" />;
};
