import {
  DirectionalLight,
  PlaneBufferGeometry,
  MeshPhongMaterial,
  HemisphereLight,
  ShaderMaterial,
  SphereGeometry,
  Mesh,
  Fog,
  Color,
  BackSide,
  PCFSoftShadowMap,
  GammaEncoding,
} from 'three/build/three.module';

import RendererBehaviour from './renderer';

import SkyboxVertexShader from '../shaders/skybox.vert';
import SkyboxFragmentShader from '../shaders/skybox.frag';

export default class StudioEnvironmentBehavior {
  constructor(props) {
    Object.assign(
      this,
      {
        radius: 1000,
        groundColor: 0xcccccc,
        skyColor: 0xe0e0e0,
        fogColor: 0xcccccc,
        groundSpecular: 0x000000, //0x00415a
      },
      props,
    );
  }

  start() {
    const scene = this.parent;
    const { radius } = this;

    scene.fog = new Fog(this.fogColor, 1, radius);

    // Lights
    const hemiLight = new HemisphereLight(this.skyColor, this.groundColor, 0.6);
    /*hemiLight.color.setHSL( 0.6, 1, 0.6 );
		hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );*/
    hemiLight.position.set(0, radius, 0);
    scene.add(hemiLight);

    const dirLight = new DirectionalLight(this.skyColor, 0.8);
    dirLight.position.set(0, 50, 0);
    scene.add(dirLight);
    dirLight.castShadow = true;
    dirLight.shadow.mapSize.width = 1024;
    dirLight.shadow.mapSize.height = 1024;
    const d = 250;
    dirLight.shadow.camera.left = -d;
    dirLight.shadow.camera.right = d;
    dirLight.shadow.camera.top = d;
    dirLight.shadow.camera.bottom = -d;
    dirLight.shadow.camera.far = 400;
    dirLight.shadow.bias = -0.01;

    // Ground
    const groundGeo = new PlaneBufferGeometry(radius * 2, radius * 2);
    const groundMat = new MeshPhongMaterial({
      color: this.groundColor,
      specular: this.groundSpecular,
      flatShading: true,
    });
    //groundMat.color.setHSL( 0.095, 1, 0.75 );
    const ground = new Mesh(groundGeo, groundMat);
    ground.name = 'Studio Ground';
    ground.rotation.x = -Math.PI / 2;
    ground.position.y = -25;
    scene.add(ground);
    ground.receiveShadow = true;

    // SKYDOME
    var uniforms = {
      topColor: {
        value: new Color(0xffffff),
      },
      bottomColor: {
        value: new Color(this.groundColor),
      },
      offset: {
        value: 33,
      },
      exponent: {
        value: 0.6,
      },
    };
    uniforms.topColor.value.copy(hemiLight.color);
    //scene.fog.color.copy(uniforms.bottomColor.value);
    var skyGeo = new SphereGeometry(radius, 32, 15);
    var skyMat = new ShaderMaterial({
      vertexShader: SkyboxVertexShader,
      fragmentShader: SkyboxFragmentShader,
      uniforms: uniforms,
      side: BackSide,
    });
    var sky = new Mesh(skyGeo, skyMat);
    sky.name = 'Sky Dome';
    scene.add(sky);

    const { renderer } = scene.getBehaviourByType(RendererBehaviour);
    // renderer.setClearColor( scene.fog.color ); // somehow skips anti-alias pass
    renderer.outputEncoding = GammaEncoding;
    renderer.shadowMap.enabled = true;
    // renderer.shadowMap.renderReverseSided = false;
    renderer.shadowMap.type = PCFSoftShadowMap;
  }
}
