import { Vector3 } from 'three/build/three.module';
import anime from 'animejs';

export default class CameraBehaviour {
  start() {
    const { parent } = this;
    const { camera } = parent;
    camera.position.z = 100;
    camera.position.y = 0;
    camera.lookAt(new Vector3());
    this.angle = 0;
  }

  panTo(lookAt, offset = new Vector3(0, 0, 100)) {
    const { x, y, z } = this.camera.position.clone().add(offset);
    anime({
      targets: this.camera.position,
      x,
      y,
      z,
      duration: 1000,
    });
  }

  getLookAtDirection() {
    const { camera } = this.parent;
    var vector = new Vector3(0, 0, -1);
    vector.applyEuler(camera.rotation, camera.eulerOrder);
    return vector;
  }
}
