import RendererBehaviour from './renderer';
import { Vector2, Vector3, Raycaster } from 'three/build/three.module';

export default class MouseBehaviour {
  start() {
    const elem = (this.domElement = this.parent.getBehaviourByType(
      RendererBehaviour,
    ).renderer.domElement);

    this.mouse = {
      down: false,
      position: new Vector2(0, 0),
    };
    elem.addEventListener('mousemove', this.handleMouseMove);
    elem.addEventListener('mousedown', this.handleMouseDown);
    elem.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove = e => {
    this.mouse.position.set(e.clientX, e.clientY);
    this.needsUpdate = true;
  };

  handleMouseDown = () => {
    this.mouse.down = true;
    this.dispatch('down', this._intersects || []);
  };

  handleMouseUp = () => {
    this.mouse.down = false;
    this.dispatch('up', this._intersects || []);
    this.dispatch('click', this._intersects || []);
  };

  stop() {
    const elem = this.domElement;
    elem.removeEventListener('mousemove', this.handleMouseMove);
    elem.removeEventListener('mousedown', this.handleMouseDown);
    elem.removeEventListener('mouseup', this.handleMouseUp);
  }

  update() {
    if (!this.needsUpdate) return;
    this.needsUpdate = false;

    const scene = this.parent;
    const { camera } = scene;

    const m3d = new Vector3(
      (this.mouse.position.x / this.domElement.clientWidth) * 2 - 1,
      (this.mouse.position.y / this.domElement.clientHeight) * -2 + 1,
      0.5,
    );
    const ray = new Raycaster();
    ray.setFromCamera(m3d, camera);

    const interactiveObjects = scene.findChildren({ interactive: true }, true);

    let objects = [];
    interactiveObjects.forEach(object => {
      objects.push(object);
      if ('flattenChildren' in object) {
        objects = objects.concat(object.flattenChildren());
      } else {
        objects = objects.concat(object.children);
      }
    });

    const intersects = ray.intersectObjects(objects);

    //dispatch
    const lastIntersects = this._intersects || [];
    const dispatchOver = [];
    const dispatchOut = [];
    for (let i = 0; i < lastIntersects.length; i++) {
      let stillHovered = false;
      for (let intersect of intersects) {
        if (
          lastIntersects[i].object === intersect.object &&
          lastIntersects[i].faceIndex === intersect.faceIndex
        ) {
          stillHovered = true;
        }
      }
      if (!stillHovered) dispatchOut.push(lastIntersects[i]);
    }

    for (let i = 0; i < intersects.length; i++) {
      let newlyHovered = true;
      for (let lastIntersect of lastIntersects) {
        if (
          intersects[i].object === lastIntersect.object &&
          intersects[i].faceIndex === lastIntersect.faceIndex
        ) {
          newlyHovered = false;
        }
      }
      if (newlyHovered) dispatchOver.push(intersects[i]);
    }

    this._intersects = intersects;
    this.dispatch('mouseout', dispatchOut); // order is paramount!
    this.dispatch('mouseover', dispatchOver);
  }

  dispatch(which, list) {
    const dom = this.domElement;
    list.forEach(intersect => {
      const e = new window.CustomEvent(`${which}3d`, { detail: intersect });
      dom.dispatchEvent(e);
    });
  }
}
