import Prefab from '../prefab';
import {
  Object3D,
  Geometry,
  MeshPhongMaterial,
  Mesh,
  TextureLoader,
  Vector2,
  Texture,
  GammaEncoding,
  CanvasTexture,
} from 'three/build/three.module';
import getTetrahedronChain from '../tetrahedron-chain';
import anime from 'animejs';
import MultiTexture from '../multi-texture';
import { nube as nubeContent } from '../../content';

const cleanTextureCanvas = (() => {
  const canvas = document.createElement('canvas');
  canvas.width = 512;
  canvas.height = 512;
  const context = canvas.getContext('2d');
  context.fillStyle = 'rgb(232,232,232)';
  context.fillRect(0, 0, 512, 512);

  return canvas;
})();

class KaleidocycleGeometry extends Geometry {
  constructor(props) {
    super();
    this.props = props;
    this.dynamic = true;
    this.updateTet(props);
    this.updateUvs(false);
  }

  updateTet({ Theta, h, Sides, PA, PB, QC, QD }) {
    const tet = getTetrahedronChain(Theta, h, Sides, PA, PB, QC, QD);

    Object.assign(this, {
      vertices: tet[0],
      elementsNeedUpdate: true,
      verticesNeedUpdate: true,
      uvsNeedUpdate: true,
    });

    if (!this.faces || this.faces.length !== tet[1].length) {
      this.faces = tet[1];
      this.computeBoundingSphere();
    }

    this.computeFaceNormals();
    this.computeVertexNormals();
  }

  updateUvs() {
    const scope = this;

    let faces = scope.faces;

    let kSideFaceIndex = [0, 0, 0, 0];

    for (let i = 0; i < faces.length; i++) {
      let uvs;

      const side = i % 4;

      switch (side) {
        case 0:
        case 3:
          uvs = [new Vector2(0, 0), new Vector2(1, 0), new Vector2(0.5, 1)];
          break;
        case 1:
          if (kSideFaceIndex[side] % 2 === 0) {
            uvs = [new Vector2(0, 0), new Vector2(1, 0), new Vector2(0.5, 1)];
          } else {
            uvs = [new Vector2(0.5, 1), new Vector2(0, 0), new Vector2(1, 0)];
          }
          break;
        case 2:
          if (kSideFaceIndex[side] % 2 === 0) {
            uvs = [new Vector2(0.5, 1), new Vector2(0, 0), new Vector2(1, 0)];
          } else {
            uvs = [new Vector2(1, 0), new Vector2(0.5, 1), new Vector2(0, 0)];
          }
          break;
        default:
      }

      kSideFaceIndex[side]++;
      scope.faceVertexUvs[0].push(uvs);
    }

    scope.uvsNeedUpdate = true;
  }
}

export default class Kaleidocycle extends Prefab(Object3D) {
  constructor(colorKey) {
    super();
    this._surfaceKey = Object.keys(nubeContent.surfaces)[0];
    this.colorKey = colorKey;
    this.ao = this.loadTexture('/img/textures/ao/diffuse.png');
    this.ao.opacity = 0.1;
    this.outlineTexture = this.getOutlineTexture(
      nubeContent.colors[this.colorKey].faceAColor,
    );
    const m = this.getMesh();
    //m.rotation.y = Math.PI / -9;
    this.add(m);
  }

  loadTexture(...props) {
    this.textureLoader = this.textureLoader || new TextureLoader();
    const texture = this.textureLoader.load(...props);
    texture.encoding = GammaEncoding;

    return texture;
  }

  getOutlineTexture(color) {
    const outlineCanvas = document.createElement('canvas');
    outlineCanvas.width = 256;
    outlineCanvas.height = 256;
    const outlineContext = outlineCanvas.getContext('2d');

    outlineContext.fillStyle = 'transparent';
    outlineContext.strokeStyle = color; //'2px solid #00ff00';
    outlineContext.lineWidth = 2;
    outlineContext.beginPath();
    outlineContext.moveTo(128, 0);
    outlineContext.lineTo(256, 256);
    outlineContext.lineTo(0, 256);
    outlineContext.closePath();
    outlineContext.stroke();

    // document.body.appendChild(outlineCanvas);
    // outlineCanvas.style.position = 'absolute';
    // outlineCanvas.style.top = '0px';

    const texture = new Texture(outlineCanvas);
    texture.encoding = GammaEncoding;
    return texture;
  }

  getSurfaceTexture(index) {
    this.surfaceTextures = this.surfaceTextures || [];

    const { surfaces } = nubeContent;

    const t = new MultiTexture(
      Object.keys(nubeContent.surfaces)
        .map(surfaceKey => {
          const { nuances } = surfaces[surfaceKey];
          const { filename, name } = nuances[this.colorKey][index];
          const low = filename.split('.jpg').join('.lowres.sm.jpg');
          const path = `/img/textures/surfaces/${this.colorKey}/${surfaceKey}/${low}`;
          const surfaceT = this.loadTexture(path, () => t.compose());
          surfaceT.surfaceKey = surfaceKey;
          surfaceT.nuanceIndex = index;
          surfaceT.colorKey = this.colorKey;
          surfaceT.name = name;
          surfaceT.active = this._surfaceKey === surfaceKey;
          surfaceT.opacity = surfaceT.active ? 1 : 0;
          return surfaceT;
        })
        .concat([this.ao, this.outlineTexture]),
    );

    this.surfaceTextures.push(t);
    return t;
  }

  getFaceMaterial(index) {
    this.faceMaterials = this.faceMaterials || [];
    const faceMaterial = new MeshPhongMaterial({
      map: this.getSurfaceTexture(index),
      flatShading: true,
    });
    this.faceMaterials.push(faceMaterial);
    return faceMaterial;
  }

  setSurfaceKey(key, animated = true) {
    if (key === this._surfaceKey) return Promise.resolve();
    const oldKey = this._surfaceKey;
    const fadeOutTargets = [];
    const fadeInTargets = [];

    this.surfaceTextures.forEach(multi => {
      multi.eachLayer(t => {
        if (t === this.ao) return;
        if (t.surfaceKey === key) {
          fadeInTargets.push(t);
          t.active = true;
        } else if (t.surfaceKey === oldKey) {
          fadeOutTargets.push(t);
          t.active = false;
        }
      });
    });
    this._surfaceKey = key;

    if (!animated) {
      fadeInTargets.forEach(t => (t.opacity = 1));
      fadeOutTargets.forEach(t => (t.opacity = 0));
      this.surfaceTextures.forEach(multi => multi.compose());
      this.faceMaterials.forEach(mat => (mat.needsUpdate = true));
      return Promise.resolve();
    }

    const progress = { value: 0 };
    anime({
      targets: progress,
      value: 1,
      easing: 'easeInOutSine',
      duration: 500,
      delay: 250,
      update: () => {
        const v = +progress.value;
        fadeInTargets.forEach(t => (t.opacity = v));
        fadeOutTargets.forEach(t => (t.opacity = 1 - v));
        this.surfaceTextures.forEach(multi => multi.compose());
        this.faceMaterials.forEach(mat => (mat.needsUpdate = true));
      },
    });

    return this.animateTheta();
  }

  getSurfaceKey() {
    return this._surfaceKey;
  }

  getMesh(size = 10) {
    this.geometryProps = {
      Theta: 0,
      Sides: 6,
      h: size * Math.sqrt(3),
      PA: size,
      PB: size,
      QC: size,
      QD: size,
    };
    const geometry = new KaleidocycleGeometry(this.geometryProps);

    const cleanSurfaceMaterialA = new MeshPhongMaterial({
      color: parseInt(
        nubeContent.colors[this.colorKey].faceAColor.split('#').join('0x'),
      ),
      map: new MultiTexture(
        [new CanvasTexture(cleanTextureCanvas), this.ao, this.outlineTexture],
        true,
        true,
      ),
      flatShading: true,
    });
    const cleanSurfaceMaterialB = new MeshPhongMaterial({
      color: parseInt(
        nubeContent.colors[this.colorKey].faceBColor.split('#').join('0x'),
      ),
      map: new MultiTexture(
        [new CanvasTexture(cleanTextureCanvas), this.ao, this.outlineTexture],
        true,
        true,
      ),
      flatShading: true,
    });
    const kmats = [
      this.getFaceMaterial(1),
      this.getFaceMaterial(0),
      cleanSurfaceMaterialB,
      cleanSurfaceMaterialA,
      this.getFaceMaterial(3),
      this.getFaceMaterial(2),
    ];

    const m = new Mesh(geometry, kmats);
    m.castShadow = true;
    m.rotation.z = (Math.PI / 6) * 3;

    return m;
  }

  animateTheta(params = {}) {
    //https://matthewlein.com/ceaser/

    const animeParams = Object.assign(
      {
        duration: 1500,
        easing: [0.625, 0.005, 0.07, 1],
        targets: { Theta: this.geometryProps.Theta },
        Theta: Math.ceil(this.geometryProps.Theta / 90 + 1) * 90,
      },
      params,
      {
        update: () => {
          this.geometryProps.Theta = +animeParams.targets.Theta;
          this.children[0].geometry.updateTet(this.geometryProps);
          if (params.update) {
            params.update(animeParams.targets.Theta);
          }
        },
      },
    );

    if (animeParams.from !== undefined && animeParams.to !== undefined) {
      animeParams.targets.p = animeParams.from;
      delete animeParams.from;

      animeParams.p = animeParams.to;
      delete animeParams.to;
    }

    if (this._thetaAnimation) {
      this._thetaAnimation.pause();
    }

    return (this._thetaAnimation = anime(animeParams)).finished;
  }

  update(delta) {
    this._hover = (this._hover || 0) + delta;
    this.children[0].position.y = Math.cos(this._hover / 1.5) * 0.8;
  }
}
