import { ucfirst } from '../util';

const cache = [[], []];

export const traverseable = (property, Superclass) => {
  property = Array.isArray(property) ? property : [property, `${property}s`];
  const plu = property[1];
  const [Sing, Plu] = property.map(ucfirst);

  return class extends Superclass {
    [`filter${Plu}`](filter, deep = false, target) {
      if (target === undefined) {
        this[plu] = this[plu] || [];
        target = this[plu];
      }
      let results = [];

      target.forEach(inst => {
        if (filter === true || filter(inst)) {
          results.push(inst);
        }
        if (deep && plu in inst && Array.isArray(inst[plu])) {
          results = results.concat(
            this[`filter${Plu}`](filter, true, inst[plu]),
          );
        }
      });

      return results;
    }
    [`filter${Sing}`](filter, deep) {
      return this[`filter${Plu}`](filter, deep)[0];
    }
    [`flatten${Plu}`]() {
      return this[`filter${Plu}`](true, true);
    }
    [`find${Plu}`](conditions, deep) {
      const filter = b => {
        for (let key of Object.keys(conditions)) {
          if (conditions[key] !== b[key]) return false;
        }
        return true;
      };
      return this[`filter${Plu}`](filter, deep);
    }
    [`find${Sing}`](conditions, deep) {
      return this[`find${Plu}`](conditions, deep)[0];
    }
    [`get${Plu}ByType`](Type, deep) {
      return this[`filter${Plu}`](inst => inst instanceof Type, deep);
    }
    [`get${Sing}ByType`](Type, deep) {
      return this[`filter${Sing}`](inst => inst instanceof Type, deep);
    }
    [`each${Sing}`](fn) {
      this[plu] = this[plu] || [];
      this[plu].forEach(fn);
      return this;
    }
    [`map${Plu}`](fn) {
      this[plu] = this[plu] || [];
      return this[plu].map(fn);
    }
  };
};

export default ParentClass => {
  const cacheIndex = cache[0].indexOf(ParentClass);

  if (cacheIndex > -1) return cache[1][cacheIndex];

  class Prefab extends ParentClass {
    constructor(props, ...args) {
      super(...args);
      props && Object.assign(this, props);
    }

    addBehaviour(instance) {
      if (typeof instance !== 'object') {
        throw new Error('invalid behavior instance');
      }

      this.behaviours = this.behaviours || [];

      this.behaviours.push(instance);

      instance.parent = this;

      if (typeof instance.start === 'function') {
        instance.start();
      }

      return this;
    }

    removeBehaviour(instance) {
      let index;
      this.behaviours = this.behaviours || [];

      if (
        typeof instance !== 'object' ||
        (index = this.behaviours.indexOf(instance)) < 0
      ) {
        throw new Error('invalid behavior instance');
      }

      this.behaviours.splice(index, 1);

      if (typeof instance.stop === 'function') {
        instance.stop();
      }

      instance.parent = null;

      return this;
    }

    fire(type, ...args) {
      this.behaviours &&
        this.behaviours.forEach(b => b[type] && b[type](...args));
      this.children && this.children.forEach(c => c[type] && c[type](...args));
    }

    update(delta) {
      this.fire('update', delta);
    }

    render(delta) {
      this.fire('render', delta);
    }

    stop() {
      this.eachChild(child => this.remove(child));
      this.eachBehaviour(behaviour => this.removeBehaviour(behaviour));
    }
  }

  Prefab = traverseable(['child', 'children'], Prefab);
  Prefab = traverseable('behaviour', Prefab);

  cache[0].push(ParentClass);
  cache[1].push(Prefab);

  return Prefab;
};
