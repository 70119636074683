import React, { Component } from 'react';
import * as content from '../content';
import ColorNavigation from './color-navigation';
import NuanceLabel from './nuance-label';
import { Header, Footer } from './layout';
import SceneWrapper from './scene-wrapper';
import Loader from './loader';
import ResponsiveNavigation from './responsive-nav';
import SurfacesView from './view/surfaces';

export default class Application extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    hoveredNuanceIndex: -1,
    loaded: false,
    responsive: false,
  };

  componentDidMount() {
    this.preload();
    window.addEventListener('resize', () => {
      this.setState({ responsive: window.innerWidth < 1200 });
    });
    this.setState({ responsive: window.innerWidth < 1200 });
  }

  preload() {
    const { colors, surfaces } = content.nube;
    const queue = [
      '/img/textures/ao/diffuse.png',
      '/img/textures/clean/diffuse.jpg',
    ];
    Object.keys(colors).forEach(colorKey => {
      Object.keys(surfaces).forEach(surfaceKey => {
        const nuances = surfaces[surfaceKey].nuances[colorKey];
        nuances.forEach(({ filename }) => {
          queue.push(
            `/img/textures/surfaces/${colorKey}/${surfaceKey}/${filename
              .split('.jpg')
              .join('.lowres.sm.jpg')}`,
          );
        });
      });
    });

    const total = queue.length;
    let loaded = 0;
    queue.forEach(src => {
      const img = new Image();
      img.onload = () => {
        loaded++;
        if (loaded === total) {
          this.setState({ loaded: true });
        }
      };
      img.src = src;
    });
  }

  resolveRouteParams() {
    const defaults = {
      colorKey: Object.keys(content.nube.colors)[0],
      surfaceKey: Object.keys(content.nube.surfaces)[0],
      nuanceIndex: -1,
      highFPS: false,
    };
    if (!this.context.router.route) return defaults;
    const params = this.context.router.route;
    return Object.assign(defaults, params);
  }

  renderLayout() {
    const {
      nuanceIndex,
      surfaceKey,
      colorKey,
      highFPS,
    } = this.resolveRouteParams();
    const nuances =
      surfaceKey && colorKey
        ? content.nube.surfaces[surfaceKey].nuances[colorKey]
        : null;
    const { hoveredNuanceIndex, loaded, responsive } = this.state;

    return (
      <div id="page-wrap">
        <Header content={content.header} showMenu={!responsive} />
        {loaded && !responsive && (
          <SceneWrapper
            highFPS={highFPS}
            surfaceKey={surfaceKey}
            colorKey={colorKey}
            onClick={nuanceIndex => {
              if (nuanceIndex === -1) return;
              this.context.router.history.push(
                `/oberflaechen/${colorKey}/${surfaceKey}/${nuanceIndex}`,
              );
            }}
            onMouseOver={hoveredNuanceIndex =>
              this.setState({ hoveredNuanceIndex })
            }
            onMouseOut={() => this.setState({ hoveredNuanceIndex: -1 })}
          />
        )}
        {loaded && responsive && (
          <div className="nube-view active">
            <SurfacesView
              closeable={false}
              baseRoute={``}
              params={{ surfaceKey, colorKey, nuanceIndex }}
            />
          </div>
        )}
        <NuanceLabel
          nuances={nuances}
          nuanceIndex={hoveredNuanceIndex}
          open={hoveredNuanceIndex !== -1}
        />
        {!responsive && (
          <ColorNavigation
            in={loaded}
            selectedColorKey={colorKey}
            selectedSurfaceKey={surfaceKey}
            onSelect={(colorKey, surfaceKey) =>
              this.setState({ colorKey, surfaceKey })
            }
          />
        )}
        {this.props.children}
        {!responsive && <Footer content={content.footer} />}
      </div>
    );
  }

  render() {
    const { loaded, responsive } = this.state;
    const { nuanceIndex, surfaceKey, colorKey } = this.resolveRouteParams();
    return (
      <div>
        <Loader active={!loaded} delay={true} />
        {this.renderLayout()}
        {loaded && responsive && (
          <ResponsiveNavigation
            {...{ surfaceKey, colorKey, nuanceIndex }}
            active={responsive}
          />
        )}
      </div>
    );
  }
}

export class NotFoundView extends Component {
  render() {
    return <span />;
  }
}
