import Application from './prefabs/application';
import RendererBehaviour from './behaviours/renderer';

export default (selector = document.body) => {
  if (typeof selector === 'string') selector = document.querySelector(selector);

  const application = new Application();
  application.getBehaviourByType(RendererBehaviour).appendTo(selector);

  return application;
};
