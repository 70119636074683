import React, { Component } from 'react';
import { Header } from '../layout';
import * as content from '../../content';
import Swipeable from 'react-swipeable';
import classNames from 'classnames';
import Icon from '../icon';
import { Link } from '../router';
const { surfaces, colors } = content.nube;
const surfaceKeys = Object.keys(surfaces);
const colorKeys = Object.keys(colors);

export const flatten = colorKey => {
  let list = [];
  surfaceKeys.forEach(sKey => {
    list = list.concat(
      surfaces[sKey].nuances[colorKey].map((nuance, index) =>
        Object.assign({ surfaceKey: sKey, index, colorKey }, nuance),
      ),
    );
  });

  return list;
};

export const flattenedNuances = {};
colorKeys.forEach(cKey => (flattenedNuances[cKey] = flatten(cKey)));

export default class SurfacesView extends Component {
  static contextTypes = {
    router: () => null,
  };

  state = {
    containerWidth: 0,
    slidesToShow: 1,
    touchStart: null,
    offset: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyDown);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleResize = () => {
    const containerWidth = window.innerWidth || window.screen.width;
    const breakpoints = this.props.breakpoints || [
      [0, 1.8],
      [480, 1.8],
      [768, 2],
      [992, 3],
      [1200, 4],
      [1600, 4],
    ];
    let slidesToShow = 1;
    for (let i = 0; i < breakpoints.length; ++i) {
      const [breakpoint, slides] = breakpoints[i];
      if (breakpoint < containerWidth) {
        slidesToShow = slides;
      } else {
        break;
      }
    }
    this.setState({ containerWidth, slidesToShow });
  };

  getDimensions = () => {
    const { containerWidth, slidesToShow } = this.state;
    const outerSlideWidth = containerWidth / slidesToShow;
    const innerSlideWidth = containerWidth / Math.ceil(slidesToShow);
    const slideGap = outerSlideWidth - innerSlideWidth;
    const marginLeft = containerWidth / 2 - outerSlideWidth / 2;
    return { outerSlideWidth, innerSlideWidth, slideGap, marginLeft };
  };

  renderItems(colorKey, currentSurfaceKey, currentNuanceIndex) {
    const { isDrag, touchStart, touch } = this.state;
    const {
      outerSlideWidth,
      innerSlideWidth,
      slideGap,
      marginLeft,
    } = this.getDimensions();
    let activeIndex = 0;
    const items = flattenedNuances[colorKey].map((nuance, index) => {
      const nuanceIndex = nuance.index;
      const sKey = nuance.surfaceKey;
      const low = nuance.filename.split('.jpg').join('.lowres.md.jpg');
      const src = `/img/textures/surfaces/${colorKey}/${sKey}/${low}`;
      const active =
        !isDrag &&
        nuanceIndex === currentNuanceIndex &&
        sKey === currentSurfaceKey;
      ///detail/${colorKey}/${sKey}/${nuanceIndex}
      if (active) activeIndex = index;

      return (
        <div
          key={`${colorKey}/${sKey}/${nuanceIndex}`}
          style={{
            width: `${innerSlideWidth}px`,
            margin: `0 ${slideGap / 2}px`,
          }}
          className={classNames('item', { active })}
        >
          <div className="item-inner">
            <img src={src} alt={nuance.title} />
            <h4>{nuance.title}</h4>
          </div>
        </div>
      );
    });

    let offset = 0;

    if (!isDrag) {
      offset = this.state.offset = activeIndex * outerSlideWidth - marginLeft;
    } else {
      offset = this.state.offset + (touchStart.x - touch.x);
    }

    return (
      <div
        className={classNames('track', { drag: isDrag })}
        style={{
          transform: `translateX(${offset * -1}px)`,
          width: items.length * outerSlideWidth,
        }}
      >
        {items}
      </div>
    );
  }

  handleSwiping = (e, deltaX, deltaY, absX, absY, velocity) => {
    const touch = { x: deltaX * -1, y: deltaY };
    const stateUpdate = { touch, isDrag: true };
    if (!this.state.isDrag) {
      stateUpdate.touchStart = Object.assign(
        { offset: this.state.offset },
        touch,
      );
    }
    this.setState(stateUpdate);
  };

  clamp = index => Math.max(Math.min(index, this.getLength() - 1), 0);

  handleSwiped = (e, deltaX, deltaY, isFlick, velocity) => {
    const { touchStart, touch } = this.state;

    const gotoIndex = index => {
      const { colorKey } = this.props.params;
      const nuance = flattenedNuances[colorKey][index];
      if (index === this.getIndexOf()) return;
      this.context.router.history.push(
        `${this.getBaseRoute()}/${colorKey}/${nuance.surfaceKey}/${
          nuance.index
        }`,
      );
    };

    this.setState({ touchStart: null, touch: null, isDrag: false });

    if (isFlick) {
      if (deltaX < 0) {
        gotoIndex(this.clamp(this.getIndexOf() - 1));
      } else {
        gotoIndex(this.clamp(this.getIndexOf() + 1));
      }
    } else {
      const { outerSlideWidth } = this.getDimensions();
      const round = 'round'; //deltaX < 0?"floor":"ceil";
      const travel = Math[round]((touchStart.x - touch.x) / outerSlideWidth);
      gotoIndex(this.clamp(this.getIndexOf() + travel));
    }
  };

  getIndexOf(params) {
    const { colorKey, surfaceKey, nuanceIndex } = params || this.props.params;
    for (let i = 0; i < flattenedNuances[colorKey].length; ++i) {
      const nuance = flattenedNuances[colorKey][i];
      if (nuance.index === +nuanceIndex && nuance.surfaceKey === surfaceKey)
        return i;
    }

    return 0;
  }

  getLength(params = this.props.params) {
    return flattenedNuances[params.colorKey].length;
  }

  getNuanceAt(index) {
    const { colorKey } = this.props.params;
    return flattenedNuances[colorKey][index];
  }

  handleTap = e => {
    const { outerSlideWidth, marginLeft } = this.getDimensions();
    const tapPos = e.clientX + this.getIndexOf() * outerSlideWidth - marginLeft;
    const targetIndex = Math.floor(tapPos / outerSlideWidth);

    if (targetIndex === this.clamp(targetIndex)) {
      const { colorKey, surfaceKey, index } = this.getNuanceAt(targetIndex);
      this.context.router.history.push(
        `/detail/${colorKey}/${surfaceKey}/${index}`,
      );
    }
  };

  hasNext = () => {
    return this.getIndexOf() < this.getLength() - 1;
  };

  hasPrev = () => {
    return this.getIndexOf() > 0;
  };

  getBaseRoute() {
    return this.props.baseRoute !== undefined
      ? this.props.baseRoute
      : '/oberflaechen';
  }

  nextUri = () => {
    const index = this.clamp(this.getIndexOf() + 1);
    const { colorKey } = this.props.params;
    const nuance = flattenedNuances[colorKey][index];
    if (index === this.getIndexOf()) return null;
    return `${this.getBaseRoute()}/${colorKey}/${nuance.surfaceKey}/${
      nuance.index
    }`;
  };

  prevUri = () => {
    const index = this.clamp(this.getIndexOf() - 1);
    const { colorKey } = this.props.params;
    const nuance = flattenedNuances[colorKey][index];
    if (index === this.getIndexOf()) return null;
    return `${this.getBaseRoute()}/${colorKey}/${nuance.surfaceKey}/${
      nuance.index
    }`;
  };

  handleKeyDown = ({ keyCode }) => {
    if (!this.props.active) return;
    if (keyCode === 37) {
      const uri = this.prevUri();
      uri && this.context.router.history.push(uri);
    }
    if (keyCode === 39) {
      const uri = this.nextUri();
      uri && this.context.router.history.push(uri);
    }
  };

  render() {
    let { surfaceKey, colorKey, nuanceIndex } = this.props.params || {};
    const closeable = this.props.closeable !== false;

    return (
      <div>
        <div className={classNames('nube-surfaces')}>
          <Header content={content.header} showMenu={false} />
          {closeable && (
            <Link
              className="closebtn inverse"
              to={`/${colorKey}/${surfaceKey}`}
              component="button"
            >
              <Icon which="cross" />
            </Link>
          )}

          <Link
            className={classNames('nextbtn', { disabled: !this.hasNext() })}
            to={this.nextUri()}
          >
            <Icon which="arrow-right" />
          </Link>

          <Link
            className={classNames('prevbtn', { disabled: !this.hasPrev() })}
            to={this.prevUri()}
          >
            <Icon which="arrow-left" />
          </Link>

          <Swipeable
            className="carousel"
            style={{ touchAction: 'none' }}
            trackMouse={true}
            onSwiping={this.handleSwiping}
            onSwiped={this.handleSwiped}
            onTap={this.handleTap}
            flickThreshold={4}
          >
            {this.renderItems(colorKey, surfaceKey, +nuanceIndex)}
          </Swipeable>
        </div>
      </div>
    );
  }
}
