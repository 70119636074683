try {
  module.exports = JSON.parse(
    document.querySelector('[data-role="nube-data"]').textContent,
  );
} catch (e) {
  if (process.env.NODE_ENV === 'development') {
    console.error('no content data found', e);
  }
  module.exports = {};
}
