import { Link } from './router';
import classNames from 'classnames';
import React from 'react';
import { omit } from '../util';

export const Navigation = props => {
  const { items, forceExternal } = props;
  const navProps = omit(props, 'items', 'forceExternal');
  return (
    <nav {...navProps}>
      <ul>
        {items &&
          items.map(({ href, to, title, primary, classNameString}, key) => (
            <li key={key}>
              <Link
                target={forceExternal ? '_blank' : null}
                className={classNames({primary},classNameString)} 
                key={key}
                {...{ href, to }}
              >
                {title}
              </Link>
            </li>
          ))}
      </ul>
    </nav>
  );
};

export const Header = ({ content, showMenu, style }) => {
  const { brand, items } = content;
  return (
    <header id="nube-header" style={style}>
      <Link className="nube-header__brand" to="/">
        <img alt={'RINN GmbH'} {...brand} />
      </Link>
      {showMenu && <Navigation className="nube-header__nav" items={items} />}
    </header>
  );
};

export const Footer = ({ content }) => {
  const { copyright, items } = content;
  return (
    <footer id="nube-footer">
      <Navigation
        className="nube-footer__nav"
        items={items}
        forceExternal={true}
      />
      <span className="nube-footer__copyright">{copyright}</span>
    </footer>
  );
};
