import { Geometry, LineDashedMaterial, Line } from 'three/build/three.module';
import anime from 'animejs';

export default class MotionPathBehaviour {
  props = {
    curve: null,
    drawLine: false,
    drawDivisions: 50,
    drawColor: 0xff00ff,
  };

  constructor(props) {
    Object.assign(this.props, props);
  }

  animation(targetV3, params) {
    const { curve } = this.props;

    if (!curve) throw new Error('no curve to animate');

    const animeParams = Object.assign(
      {
        easing: 'linear',
        duration: 1000,
        targets: { p: 0 },
        p: 1,
      },
      params,
      {
        update: () => {
          const point = curve.getPoint(animeParams.targets.p);
          targetV3.copy(point);
          if (params.update) {
            params.update(animeParams.targets.p, point.clone());
          }
        },
      },
    );

    if (animeParams.from !== undefined && animeParams.to !== undefined) {
      animeParams.targets.p = animeParams.from;
      delete animeParams.from;

      animeParams.p = animeParams.to;
      delete animeParams.to;
    }

    const animation = anime(animeParams);

    return animation;
  }

  start() {
    const { parent } = this;
    const { curve, drawDivisions, drawLine, drawColor } = this.props;
    if (!drawLine) return;

    if (!curve) throw new Error('no curve on motion path');

    const geometry = new Geometry();
    geometry.vertices = curve.getPoints(drawDivisions || 5);
    const material = new LineDashedMaterial({
      color: drawColor,
      dashSize: 1,
      gapSize: 0.5,
    });
    const line = (this._line = new Line(geometry, material));
    parent.add(line);
  }

  stop() {
    if (this._line) this.parent.remove(this._line);
  }
}
