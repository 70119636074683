import React, { Component } from 'react';
import Sidebar from '../sidebar';
import { pages } from '../../content';

export default class PageView extends Component {
  getPageContent(page) {
    if (!page || !pages[page]) return null;
    return (
      <article
        className="nube-page-content"
        dangerouslySetInnerHTML={{ __html: pages[page] }}
      />
    );
  }

  render() {
    const { page, active } = this.props.params || {};
    return (
      <Sidebar open={!!active} size={16}>
        {this.getPageContent(page)}
      </Sidebar>
    );
  }
}
