import React from 'react';
import ReactDOM from 'react-dom';
import Application from './application';
import PageView from './view/page';
import ColorPaletteView from './view/color-palette';
import OrderView from './view/order/index';
import SurfacesView from './view/surfaces';
import DetailView from './view/detail';
import Router, { Route } from './router';
import { nube } from '../content';

const defaults = {
  colorKey: Object.keys(nube.colors)[0],
  surfaceKey: Object.keys(nube.surfaces)[0],
  nuanceIndex: -1,
};

const AppRouter = () => (
  <Router component={Application}>
    <Route
      path="/detail/:colorKey/:surfaceKey/:nuanceIndex"
      component={DetailView}
      defaultParams={defaults}
    />
    <Route
      path="/oberflaechen/:colorKey/:surfaceKey/:nuanceIndex"
      component={SurfacesView}
      defaultParams={defaults}
    />
    <Route
      path="/:colorKey/:surfaceKey/:nuanceIndex"
      defaultParams={{ highFPS: true }}
    />
    <Route path="/:colorKey/:surfaceKey" defaultParams={{ highFPS: true }} />
    <Route path="/muster-bestellen" component={OrderView} />
    <Route path="/farbpalette" component={ColorPaletteView} />
    <Route path="/:page" component={PageView} />
    <Route path="/" reroute={`/${defaults.colorKey}/${defaults.surfaceKey}`} />
    <Route path="*" />
  </Router>
);

export default (selector = document.body) => {
  if (typeof selector === 'string') selector = document.querySelector(selector);
  ReactDOM.render(<AppRouter />, selector);
};
